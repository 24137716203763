<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: 'rectangle',
    validator(value: LoaderTypesValues) {
      return Object.values({
        rectangle: 'rectangle',
        circle: 'circle',
      }).includes(value)
    },
  },
  cssClass: {
    type: String,
    default: '',
  },
  shimmerColor: {
    type: String,
    default: '#ffffff',
  },
})

const LOADER_TYPES = { rectangle: 'rectangle', circle: 'circle' }

const LOADER_CSS_CLASSES = {
  [LOADER_TYPES.rectangle]: 'rounded',
  [LOADER_TYPES.circle]: 'rounded-full',
}

type LoaderTypesKeys = keyof typeof LOADER_TYPES
type LoaderTypesValues = (typeof LOADER_TYPES)[LoaderTypesKeys]

const SHIMMER_COLOR = '#ffffff'

function isHexColor(hexColor: string) {
  const hex = hexColor.replace('#', '')

  return (
    typeof hexColor === 'string'
    && hexColor.startsWith('#')
    && hex.length === 6
    && !Number.isNaN(Number(`0x${hex}`))
  )
}

function hexToRgb(hex: string) {
  return `${hex.match(/\w\w/g)?.map(x => +`0x${x}`)}`
}
const shimmerStyle = computed(() => {
  const rgb = isHexColor(props.shimmerColor)
    ? hexToRgb(props.shimmerColor)
    : SHIMMER_COLOR

  return {
    backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0) 0%, rgba(${rgb}, 0.2) 20%, rgba(${rgb}, 0.5) 60%, rgba(${rgb}, 0))`,
  }
})

const loaderClass = computed(() =>
  props.cssClass ? props.cssClass : LOADER_CSS_CLASSES[props.type],
)
</script>

<template>
  <div class="relative overflow-hidden" :class="[loaderClass]">
    <div
      class="shimmer absolute top-0 right-0 bottom-0 left-0"
      :style="shimmerStyle"
    />
    <slot />
  </div>
</template>

<style lang="css" scoped>
.shimmer {
  transform: translateX(-100%);
  animation: shimmer 1.4s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
